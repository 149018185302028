//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MasterLayout from '@/layouts/MasterLayout.vue';

export default {
    name: 'app',
    components: {
        MasterLayout
    },
};
