//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Header from '@/components/shared/Header.vue';
import Footer from '@/components/shared/Footer.vue';

export default {
    name: "master_layout",
    components: {
        Header,
        Footer
    }
};

