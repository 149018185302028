//https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-vue-app-with-vue-i18n
//https://lokalise.com/blog/vue-i18n/
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}

export default new VueI18n({
    locale: localStorage.lang || process.env.VUE_APP_I18N_LOCALE || 'ar',
    fallbackLocale: localStorage.lang || process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'ar',
    messages: loadLocaleMessages()
})